import atardecer from './images/atardecer.webp'
import './styles/photo.css'

const Photo=()=>{
    return(
        <div className='atardecer'>
        <img className='atardecer' src={atardecer} alt='atardecer'/>
        </div>
    )
}

export default Photo